import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash-es';
import ky, { HTTPError } from 'ky';
import packageJson from '@/../package.json';

export const api = ky.create({
  prefixUrl: SERVER_URL != '' ? SERVER_URL : '/',
  credentials: 'include',
  timeout: 1000 * 60,
  headers: {
    'X-Arcsite-App-Version': packageJson.version,
  },
});

type SuccessResponse = {
  code: 0;
  message: string;
  result: 'success';
  data: any;
};

export type ErrorResponse = {
  code: number | string;
  message: string;
  result: 'failed';
  data?: any;
};

type ResponseData = SuccessResponse | ErrorResponse;

export class APIError extends Error {
  code: number | string;
  result: 'failed';
  data?: any;
  constructor(response: ErrorResponse) {
    super(response.message);
    this.code = response.code;
    this.result = response.result;
    this.data = response.data;
  }
}

const sanitizeUrl = (url: string) => (url.startsWith('/') ? url.slice(1) : url);

const handleApiResponse = async (res: any): Promise<any> => {
  const responseData = res as unknown as ResponseData;
  if (responseData.result === 'success') {
    return responseData.data;
  } else {
    if (responseData.code === 115) {
      Cookies.remove('arcuser_auth');
      localStorage.removeItem('archiving-tasks');
      localStorage.removeItem('project-preview');
      window.location.reload();
      // 仍然需要抛出来 error， 否则调用方会得到一个undefined的结果
      throw new APIError(responseData);
    }
    throw new APIError(responseData);
  }
};
type RequestPayload = {
  [K: string]: any;
};

const callPostApi = async <T = unknown>(
  url: string,
  params: RequestPayload = {}
): Promise<T> => {
  url = sanitizeUrl(url);

  try {
    const res = await api.post(url, { json: params }).json();
    return handleApiResponse(res);
  } catch (error) {
    return handleError(error);
  }
};

const handleError = (error: any) => {
  if (error instanceof HTTPError) {
    throw new APIError({
      code: error.response.status,
      message: error.message,
      result: 'failed',
    });
  } else {
    throw error;
  }
};

const callPostFormApi = async (
  url: string,
  formData?: FormData
): Promise<unknown> => {
  url = sanitizeUrl(url);
  try {
    const res = await api
      .post(url, {
        body: formData,
      })
      .json();
    return handleApiResponse(res);
  } catch (error) {
    return handleError(error);
  }
};

const callGetApi = async <T = unknown>(
  url: string,
  params: Record<string, string | number> = {}
): Promise<T> => {
  url = sanitizeUrl(url);

  try {
    const res = await api.get(url, { searchParams: params }).json();
    return handleApiResponse(res);
  } catch (error) {
    return handleError(error);
  }
};

async function callPostApiWithPagination<T = unknown>(
  url: string,
  params?: RequestPayload,
  page_no = -1,
  page_size = -1
) {
  params = params || {};
  params = Object.assign(cloneDeep(params), {
    page_no: page_no,
    page_size: page_size,
  });

  const res = await callPostApi(url, params);
  // @ts-expect-error
  if (res.count && typeof res.count == 'string') {
    // @ts-expect-error
    res.count = parseInt(res.count);
  }
  return Promise.resolve(res);
}

const callPutS3Api = async (url: string, file: File) => {
  return ky.put(url, {
    body: file,
    headers: {
      'content-type': file.type,
    },
  });
};

export {
  callPostApi,
  callPostFormApi,
  callPostApiWithPagination,
  callGetApi,
  callPutS3Api,
};
