import { callGetApi } from '@/components/api-common';
import { MaintenanceStatus } from '@arcsite/shared/interface';
import { useQuery } from '@tanstack/react-query';

export const useMaintenanceStatus = () =>
  useQuery({
    queryKey: ['maintenance-status'],
    queryFn: () =>
      callGetApi<MaintenanceStatus>('/controller/maintenance_status'),
  });
