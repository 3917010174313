import Cookies from 'js-cookie';
// import cloneDeep from 'lodash-es/cloneDeep';
import ky, { HTTPError } from 'ky';

const defaultPrefixUrl = window.SERVER_URL !== '' ? window.SERVER_URL : '/';

const api = ky.create({
  prefixUrl: defaultPrefixUrl,
  credentials: 'include',
  timeout: 1000 * 60,
});

interface SuccessResponse<T> {
  code: 0;
  message: string;
  result: 'success';
  data: T;
}

export interface ErrorResponse<T> {
  code: number | string;
  message: string;
  result: 'failed';
  data?: T;
}

type ResponseData<T> = SuccessResponse<T> | ErrorResponse<T>;

export class APIError<T> extends Error {
  code: number | string;
  result: 'failed';
  data?: T;
  constructor(response: ErrorResponse<T>) {
    super(response.message);
    this.code = response.code;
    this.result = response.result;
    this.data = response.data;
  }
}

const sanitizeUrl = (url: string) => (url.startsWith('/') ? url.slice(1) : url);

const handleApiResponse = <T>(
  responseData: ResponseData<T>,
  _url: string
): Promise<T> => {
  if (responseData.result === 'success') {
    return Promise.resolve(responseData.data);
  }

  if (responseData.code === 115) {
    Cookies.remove('arcuser_auth');
    localStorage.removeItem('archiving-tasks');
    localStorage.removeItem('project-preview');
    if (_url !== 'user/service/drawings/get_download_links_by_share_token/') {
      window.location.reload();
    }
    // 仍然需要抛出来 error， 否则调用方会得到一个undefined的结果
    throw new APIError<T>(responseData);
  }
  throw new APIError<T>(responseData);
};

const callPostApi = async <T = unknown>(url: string, params: KeyValue = {}) => {
  const sanitizedUrl = sanitizeUrl(url);

  try {
    const res: ResponseData<T> = await api
      .post(sanitizedUrl, { json: params })
      .json();
    return handleApiResponse<T>(res, sanitizedUrl);
  } catch (error) {
    return handleError(error);
  }
};

const handleError = (error: unknown) => {
  if (error instanceof HTTPError) {
    throw new APIError({
      code: error.response.status,
      message: error.message,
      result: 'failed',
    });
  } else {
    throw error;
  }
};

const callPostFormApi = async <T = unknown>(
  url: string,
  formData?: FormData
) => {
  const sanitizedUrl = sanitizeUrl(url);
  try {
    const res: ResponseData<T> = await api
      .post(sanitizedUrl, {
        body: formData,
      })
      .json();
    return handleApiResponse(res, sanitizedUrl);
  } catch (error) {
    return handleError(error);
  }
};

const callGetApi = async <T = unknown>(
  url: string,
  params: Record<string, string | number> = {},
  prefixUrl = defaultPrefixUrl
) => {
  const sanitizedUrl = sanitizeUrl(url);

  try {
    const res: ResponseData<T> = await api
      .get(sanitizedUrl, { searchParams: params, prefixUrl })
      .json();
    return handleApiResponse<T>(res, sanitizedUrl);
  } catch (error) {
    return handleError(error);
  }
};

async function callPostApiWithPagination<T = unknown>(
  url: string,
  params?: KeyValue,
  page_no = -1,
  page_size = -1
) {
  let paramsWithPagination = params || {};
  paramsWithPagination = Object.assign(paramsWithPagination, {
    page_no,
    page_size,
  });

  const res = (await callPostApi<T>(url, paramsWithPagination)) as T & {
    count?: number;
  };
  if (res.count && typeof res.count === 'string') {
    res.count = parseInt(res.count);
  }
  return Promise.resolve(res);
}

const callPutS3Api = async (url: string, file: File) => {
  return ky.put(url, {
    body: file,
    headers: {
      'content-type': file.type,
    },
  });
};

export {
  callPostApi,
  callPostFormApi,
  callPostApiWithPagination,
  callGetApi,
  callPutS3Api,
};
