import { callPostApi, callPutS3Api } from '@/components/api-common';

export async function uploadFileToS3(
  file: File
): Promise<{ file_key: string }> {
  const { write_url, file_key } = (await callPostApi(
    '/user/service/company/request_add_file/',
    {
      file_name: file.name,
    }
  )) as { write_url: string; file_key: string };
  const res = await callPutS3Api(write_url, file);
  if (res.status === 200) {
    return {
      file_key,
    };
  } else {
    throw Error(res.statusText);
  }
}
