import { callPostApi } from '@arcsite/api';
import { OrgPaymentSettings } from './user';

export type PaymentSettings = {
  support_ach?: boolean;
  support_credit_card?: boolean;
};

export type ProjectPaymentSettings = {
  project_payment_settings: {
    support_ach: boolean | null; // 是否支持ACH支付
    support_credit_card: boolean | null; // 是否支持信用卡支付
  } | null; // 如果project上没有配置过payment_settings返回null
  org_payment_settings: OrgPaymentSettings;
};

export async function getProjectPaymentSettings(project_id: string) {
  return callPostApi<ProjectPaymentSettings>(
    'user/service/my/project/get_payment_settings/',
    {
      project_id,
    }
  );
}

export async function updateProjectPaymentSettings(
  project_id: string,
  settings: { support_ach?: boolean; support_credit_card?: boolean }
) {
  await callPostApi('user/service/my/project/update_payment_settings/', {
    project_id,
    ...settings,
  });
}
