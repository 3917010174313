import React, { lazy, Suspense, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Layout, Alert, Modal, Button } from 'antd';
import {
  Route,
  Outlet,
  useLocation,
  Routes,
  Navigate,
  useNavigate,
  Link,
  BrowserRouter,
} from 'react-router-dom';
import { showAdmin } from '@/utils/user';
import {
  CurrencyView,
  Dashboard,
  DrawingAndPDF,
  FormTemplateView,
  IntegrationsView,
  Menus,
  PaymentView,
  ProductIntegrationView,
  Products,
  EstimatingView,
  ProposalListView,
  TakeoffFormView,
  TaxRatesView,
  TokensView,
  WebhooksView,
  Wisetack,
  DomainsView,
  SSOView,
} from './containers/AdminApp';
import { useUser } from './hooks';
import { auth } from './auth';
import MainMenu from './pages/Admin/mainMenu';
import { isIpadOS } from './utils/platform';
import useHidden from './hooks/useHidden';
import Authenticated from './common/components/Authenticated';
import logoShort from '@/assets/images/logo-short.svg';
import Loading from '@/common/components/Loading';
import { CurrencyProvider } from '@/currency';
import Intercom from './common/components/Intercom';
import { GlobalNav } from './pages/Admin/GlobalNav';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { cn } from '@arcsite/utils';
import ScrollToTop from './common/components/ScrollToTop';
import AdminGuard from './common/components/AdminGuard';

const { Content, Sider } = Layout;

const PayEngineOnboarding = lazy(
  () =>
    import(
      /* webpackChunkName: "PayEngineOnboarding" */ './components/integrations/PayEngine/Onboarding'
    )
);

const PayEngineDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "PayEngineDashboard" */ './components/integrations/PayEngine/Dashboard'
    )
);

const PayEngineTransactions = lazy(
  () =>
    import(
      /* webpackChunkName: "PayEngineTransactions" */ './pages/Payment/PayEngineTransactions'
    )
);
const PayAbliTransactions = lazy(
  () =>
    import(
      /* webpackChunkName: "PayAbliTransactions" */ './pages/Payment/PayAbliTransactions'
    )
);

const ProposalDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "ProposalDetail" */ './components/proposal-templates/proposalDetail'
    )
);

const SheetsPublishAll = lazy(
  () =>
    import(
      /* webpackChunkName: "SheetsPublishAll" */ './pages/SheetsPublishAll'
    )
);

const Subscribe = lazy(
  () => import(/* webpackChunkName: "Subscribe" */ '@/pages/Subscribe')
);
const ManageAccount = lazy(
  () =>
    import(/* webpackChunkName: "ManageAccount" */ './containers/ManageAccount')
);

const AiHelp = lazy(
  () => import(/* webpackChunkName: "AiHelp" */ '@/pages/AiHelp')
);

const Proposals = lazy(
  () => import(/* webpackChunkName: "Proposals" */ '@/pages/Proposals')
);

const CreateApprovedOnDeviceProposal = lazy(
  () =>
    import(
      /* webpackChunkName: "Proposals" */ '@/pages/proposal/approved-on-device/create'
    )
);

const Projects = lazy(
  () => import(/* webpackChunkName: "Projects" */ '@/pages/Projects')
);
const Project = lazy(
  () => import(/* webpackChunkName: "Project" */ '@/pages/Project')
);
const Drawing = lazy(
  () => import(/* webpackChunkName: "Drawing" */ '@/pages/Drawing')
);
const AccountInfo = lazy(
  () =>
    import(
      /* webpackChunkName: "AccountInfo" */ '@/pages/AccountInfo/AccountInfo'
    )
);

const ProposalPreview = lazy(
  () =>
    import(
      /* webpackChunkName: "ProposalPreview" */ '@/pages/Project/proposals/ProposalPreview'
    )
);

const ProposalDetailPageRedirect = lazy(
  () =>
    import(
      /* webpackChunkName: "ProposalDetailPageRedirect" */ '@/pages/proposal/redirect'
    )
);
const ProposalTemplate = lazy(
  () =>
    import(
      /* webpackChunkName: "ProposalTemplate" */ '@/pages/proposal/proposal-template'
    )
);

const CreateProposal = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateProposal" */ '@/pages/Project/createProposal/index'
    )
);
const ArcSiteConnect = lazy(
  () => import(/* webpackChunkName: "ArcSiteConnect" */ '@/pages/connect')
);

const SuccessfulRegistration = lazy(
  () =>
    import(
      /* webpackChunkName: "SuccessfulRegistration" */ '@/pages/SuccessfulRegistration'
    )
);

const FenceOnboarding = lazy(
  () =>
    import(/* webpackChunkName: "FenceOnboarding" */ '@/pages/FenceOnboarding')
);

const Fence2 = lazy(
  () => import(/* webpackChunkName: "Fence2" */ '@/pages/Fence2')
);

const Fence3 = lazy(
  () => import(/* webpackChunkName: "Fence3" */ '@/pages/Fence3')
);

const UploadExistProducts = lazy(
  () =>
    import(
      /* webpackChunkName: "UploadExistProducts" */ '@/pages/UploadExistProducts'
    )
);
const UploadNormalizedProducts = lazy(
  () =>
    import(
      /* webpackChunkName: "UploadNormalizedProducts" */ '@/pages/UploadNormalizedProducts'
    )
);

const ImportLibrary = lazy(
  () => import(/* webpackChunkName: "ImportLibrary" */ '@/pages/ImportLibrary')
);

const ForgotPassword = lazy(
  () =>
    import(/* webpackChunkName: "ForgotPassword" */ './pages/ForgotPassword')
);
const InvitationToOrg = lazy(
  () =>
    import(/* webpackChunkName: "InvitationToOrg" */ './pages/InvitationToOrg')
);
const CompanyInvitation = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyInvitation" */ './pages/CompanyInvitation'
    )
);
const Login = lazy(
  () => import(/* webpackChunkName: "Login" */ './pages/Login')
);
const SSOLogin = lazy(
  () => import(/* webpackChunkName: "Login" */ './pages/SSOLogin')
);
const Photos = lazy(
  () => import(/* webpackChunkName: "Photos" */ './pages/Photos')
);
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "ResetPassword" */ './pages/ResetPassword')
);
const SheetsHistory = lazy(
  () => import(/* webpackChunkName: "SheetsHistory" */ './pages/SheetsHistory')
);
const Signup = lazy(
  () => import(/* webpackChunkName: "Signup" */ './pages/Signup')
);
const InviteNewUser = lazy(
  () => import(/* webpackChunkName: "InviteNewUser" */ './pages/Invite')
);
const SteppedSignup = lazy(
  () => import(/* webpackChunkName: "SteppedSignup" */ './pages/SteppedSignup')
);
const SteppedSignup2 = lazy(
  () =>
    import(/* webpackChunkName: "SteppedSignup2" */ './pages/SteppedSignup2')
);
const WidgetManagement = lazy(
  () =>
    import(
      /* webpackChunkName: "WidgetManagement" */ './pages/WidgetManagement'
    )
);

const SyntaxHelp = lazy(
  () => import(/* webpackChunkName: "SyntaxHelp" */ './components/syntax-help')
);
const OrgTakeoffSetting = lazy(
  () =>
    import(
      /* webpackChunkName: "OrgTakeoffSetting" */ './components/takeoff/org_setting'
    )
);

const AccountActivate = lazy(
  () =>
    import(
      /* webpackChunkName: "AccountActivate" */ './components/account/AccountActivate'
    )
);
const UsageReport = lazy(
  () =>
    import(/* webpackChunkName: "UsageReport" */ './components/usage-report')
);
const PrivacyPolicy = lazy(
  () =>
    import(/* webpackChunkName: "PrivacyPolicy" */ './components/PrivacyPolicy')
);
const NotFound = lazy(
  () => import(/* webpackChunkName: "NotFound" */ './NotFound')
);
const PreloadPage = lazy(
  () => import(/* webpackChunkName: "PreloadPage" */ './pages/PreloadPage')
);

const DashboardPage = lazy(
  () => import(/* webpackChunkName: "DashboardPage" */ './pages/Dashboard')
);
const DashboardDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardDetailPage" */ './pages/Dashboard/subpage/detail'
    )
);

const IntegrationConnectSuccessCallback = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationConnectSuccessCallback" */ './pages/Integration/connect-success-callback'
    )
);

const Fence4Page = lazy(
  () => import(/* webpackChunkName: "fence4" */ './pages/fence4')
);

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: Error): void {
    if (!error.message.startsWith('Loading chunk')) {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <p className="text-2xl">Failed to load page. Please try again.</p>
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

function Header() {
  return (
    <div className="fixed top-0 left-0 right-0 bg-white-100 z-40 border-b border-slate-900/10">
      <div
        className="main-navigation-header flex items-center justify-between pl-4 sm:pl-6"
        style={{
          height: 56,
        }}
      >
        <div
          className="flex items-center h-full"
          // css={css`
          //   a:not(:hover) {
          //     color: #999;
          //   }
          // `}
        >
          <Link to="/" className="mr-4">
            <img
              alt="home"
              className="h-12"
              src={logoShort}
              style={{ height: '36px' }}
            />
          </Link>
          {/* {showBreadcrumb && <Nav />} */}
          <GlobalNav />
        </div>
        <div className="flex self-stretch justify-end text-xs">
          <div id="main-menu" className="self-stretch flex items-center">
            <MainMenu />
          </div>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  const hiddenArray = useHidden();
  const footerHidden = hiddenArray.includes('footer');
  if (footerHidden) return null;
  return (
    <footer id="page-footer" className="bg-white-100 fixed bottom-0 w-full">
      <div
        className="main-container flex items-center relative"
        style={{ height: 50 }}
      >
        <div className="text-center text-black-65 flex-grow">
          Copyright © 2024 ArcSite
        </div>
      </div>
    </footer>
  );
}

function Main() {
  const user = useUser();
  const location = useLocation();
  const hiddenArray = useHidden();
  const headerVisibility = hiddenArray.includes('header');

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  let content = null;
  if (user) {
    content = <Outlet />;
  }

  return (
    <Authenticated>
      <Intercom>
        <div className="flex flex-col h-full flex-grow">
          {headerVisibility ? null : <Header />}
          {headerVisibility ? null : <div style={{ height: 56 }}></div>}
          <Suspense fallback={<Loading delay={0.3} />}>
            <main
              id="arcsite-main"
              className="flex-grow h-0 overflow-auto bg-gray-f7f7f7"
            >
              {content}
            </main>
          </Suspense>
        </div>
      </Intercom>
    </Authenticated>
  );
}

function App() {
  const drawerStatus = useSelector((state: RootState) => state.drawerStatus);

  useEffect(() => {
    if (drawerStatus.status === 'OPEN') {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    } else {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    }
  }, [drawerStatus.status]);
  return (
    <SentryRoutes>
      <Route path="fence4" element={<Fence4Page />} />
      <Route path="subscribe" element={<Subscribe />} />
      <Route path="projects" element={<Projects />} />
      <Route
        path="proposals"
        element={
          <CurrencyProvider>
            <Proposals />
          </CurrencyProvider>
        }
      />
      <Route
        path="products"
        element={
          <div className="h-[calc(100vh-56px)] relative p-6">
            <CurrencyProvider>
              <Products />
            </CurrencyProvider>
          </div>
        }
      />
      <Route path="project/:id" element={<Project />} />
      <Route
        path="project/proposal-list/:projectId/"
        element={
          <CurrencyProvider>
            <Proposals />
          </CurrencyProvider>
        }
      />
      <Route
        path="proposal/signed-preview/:proposalId/"
        element={<ProposalDetailPageRedirect />}
      />
      <Route
        path="project/proposal-preview/:proposalId/"
        element={<ProposalDetailPageRedirect />}
      />
      <Route
        path="project/create-proposal/:projectId/"
        element={<CreateProposal />}
      />
      <Route
        path="proposal/detail/:proposalId/"
        element={<ProposalDetailPageRedirect />}
      />
      <Route
        path="proposal/detail/v2/:proposalId/"
        element={<ProposalPreview />}
      />
      <Route path="proposal/template" element={<ProposalTemplate />} />
      {/* 需要更新 react-router-dom 版本以支持可选参数 https://github.com/remix-run/react-router/discussions/9550 */}
      <Route
        path="drawing/:projectId/:drawingId/:versionId"
        element={<Drawing />}
      />
      <Route path="drawing/:projectId/:drawingId/" element={<Drawing />} />
      <Route path="account-info" element={<AccountInfo />} />
      <Route path="WidgetManagement" element={<WidgetManagement />} />
      <Route
        path="upload-existing-price-list"
        element={<UploadExistProducts />}
      />
      <Route
        path="upload-product-template"
        element={<UploadNormalizedProducts />}
      />
      <Route path="fenceOnboarding" element={<FenceOnboarding />} />
      <Route path="fence2" element={<Fence2 />} />
      <Route path="fence3" element={<Fence3 />} />
      <Route
        path="dashboard"
        element={
          <CurrencyProvider>
            <AdminGuard>
              <DashboardPage />
            </AdminGuard>
          </CurrencyProvider>
        }
      />
      <Route
        path="dashboard/detail"
        element={
          <CurrencyProvider>
            <AdminGuard>
              <DashboardDetailPage />
            </AdminGuard>
          </CurrencyProvider>
        }
      />
    </SentryRoutes>
  );
}

function Admin() {
  const user = useUser();
  const navigate = useNavigate();
  const isAdmin = showAdmin(user);
  const hiddenArray = useHidden();
  const asideHidden = hiddenArray.includes('aside');
  const headerHidden = hiddenArray.includes('header');
  const footerHidden = hiddenArray.includes('footer');
  const [collapsed, setCollapsed] = useState(isIpadOS());
  const drawerStatus = useSelector((state: RootState) => state.drawerStatus);

  useEffect(() => {
    if (drawerStatus.status === 'OPEN') {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    } else {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    }
  }, [drawerStatus.status]);

  useEffect(() => {
    if (!isAdmin) {
      Modal.warning({
        title: 'No Permission',
        content: 'Only admins are allowed to access the Admin Console.',
        onOk() {
          navigate('/projects');
          window.location.reload();
        },
      });
    }
  }, [isAdmin, navigate]);

  if (!isAdmin) {
    return null;
  }
  if (!user) {
    return null;
  }
  if (!showAdmin(user)) {
    return (
      <Alert
        type="error"
        message={
          'Only company administrator can manage this setting. Please contact your administrator.'
        }
      ></Alert>
    );
  }

  return (
    <Layout className="h-full">
      {!asideHidden && (
        <Sider
          style={{
            position: 'fixed',
            top: 0,
            paddingTop: headerHidden ? 0 : 56,
            height: '100%',
            overflow: 'auto',
            backgroundColor: '#fff',
          }}
          className="left-0 border-r-1 border-r-black-10 border-solid z-10"
          breakpoint="lg"
          collapsedWidth={56}
          width={216}
          collapsed={collapsed}
          onCollapse={x => {
            if (isIpadOS()) return;
            setCollapsed(x);
          }}
        >
          <div
            style={{
              flex: '1 1 0%',
              overflow: 'auto',
              overflowX: 'hidden',
              paddingBottom: footerHidden ? 0 : 56,
            }}
          >
            <Menus />
          </div>
        </Sider>
      )}
      {!asideHidden && (
        <div
          style={{
            // backgroundColor: '#001529',
            width: collapsed ? 50 : 216,
            height: '100%',
          }}
        />
      )}
      <Layout className="flex-grow mx-auto w-full">
        <Content
          style={{
            paddingRight:
              drawerStatus.status === 'CLOSE'
                ? 24
                : drawerStatus.payload?.width || 610,
          }}
          className={cn(
            'relative m-0 p-6 w-full overflow-auto',
            drawerStatus.status === 'OPEN' && 'overflow-x-auto !box-content'
          )}
        >
          <Suspense fallback={<Loading delay={0.3} />}>
            <SentryRoutes>
              <Route path="/" element={<Dashboard />} />
              <Route path="integrations" element={<IntegrationsView />} />
              <Route path="manage_accnt" element={<ManageAccount />} />
              <Route path="usage_report" element={<UsageReport />} />
              {/* <Route path="forms" element={<FormView />} /> */}
              <Route path="forms" element={<FormTemplateView />} />
              <Route path="tax_rates" element={<TaxRatesView />} />
              <Route path="payment" element={<PaymentView />} />
              <Route path="currency" element={<CurrencyView />} />
              <Route
                path="proposal-detail/:templateId"
                element={<ProposalDetail />}
              />
              <Route path="proposal-list" element={<ProposalListView />} />
              <Route
                path="takeoff_form_management"
                element={<TakeoffFormView />}
              />
              <Route path="product_management" element={<Products />} />
              <Route path="estimating" element={<EstimatingView />} />
              <Route path="wisetack" element={<Wisetack />} />
              <Route path="pay_engine" element={<PayEngineDashboard />} />
              <Route
                path="pay_engine/transactions"
                element={<PayEngineTransactions />}
              />
              <Route
                path="pay_abli/transactions"
                element={<PayAbliTransactions />}
              />
              <Route path="apitokens" element={<TokensView />} />
              <Route path="webhooks" element={<WebhooksView />} />
              <Route path="domains" element={<DomainsView />} />
              <Route path="sso" element={<SSOView />} />
              <Route path="pdf-setting" element={<DrawingAndPDF />} />
              <Route
                path="product_integration"
                element={<ProductIntegrationView />}
              />
              <Route
                path="oauth_success"
                element={<IntegrationConnectSuccessCallback />}
              />
            </SentryRoutes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

function AdminWrapper() {
  return (
    <CurrencyProvider>
      <Admin />
    </CurrencyProvider>
  );
}

const WithFooter = () => {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function Router() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Suspense fallback={<Loading delay={0.3} />}>
          <SentryRoutes>
            <Route index element={<Navigate to="/app/dashboard" replace />} />
            <Route path="/importlibrary" element={<ImportLibrary />} />
            <Route
              path="/forms"
              element={<Navigate to="/admin/forms" replace />}
            />
            <Route
              path="/proposal/approvedondevice/create"
              element={
                <CurrencyProvider>
                  <CreateApprovedOnDeviceProposal />
                </CurrencyProvider>
              }
            />
            <Route path="/ai" element={<AiHelp />} />
            <Route path="/preload" element={<PreloadPage />} />
            <Route
              path="/oauth_success"
              element={<IntegrationConnectSuccessCallback />}
            />
            <Route element={<WithFooter />}>
              <Route path="/login" element={<Login />} />
              <Route path="/login/sso" element={<SSOLogin />} />
              <Route path="/connect" element={<ArcSiteConnect />} />
              <Route path="/signup" element={<SteppedSignup2 />} />
              <Route path="/signup2" element={<SteppedSignup />} />
              <Route path="/signup-internal" element={<Signup />} />
              <Route path="/share_drawing/:token" element={<InviteNewUser />} />

              <Route
                path="/successfulRegistration"
                element={<SuccessfulRegistration />}
              />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/resetpassword/:k" element={<ResetPassword />} />
              <Route path="/accnt_active/:k" element={<AccountActivate />} />
              <Route path="/syntax_help" element={<SyntaxHelp />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/company_invitation/:token"
                element={<CompanyInvitation />}
              />
            </Route>
            <Route element={<Main />}>
              <Route
                path="/invitation_to_join_company/:k"
                element={<InvitationToOrg />}
              />
              <Route
                path="pay_engine/onboarding"
                element={<PayEngineOnboarding />}
              />
              <Route
                path="/projects"
                element={<Navigate to="/app/projects" replace />}
              />
              <Route path="/photos/:projectId" element={<Photos />} />
              <Route
                path="/sheets_hist/:projectId"
                element={<SheetsHistory />}
              />
              <Route
                path="/sheets_publish_all/:projectId/:sessionId"
                element={<SheetsPublishAll />}
              />
              <Route path="/takeoff_setting" element={<OrgTakeoffSetting />} />
              <Route path="/app/*" element={<App />} />
              <Route path="admin/*" element={<AdminWrapper />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </SentryRoutes>
        </Suspense>
        <ScrollToTop />
      </BrowserRouter>
    </ErrorBoundary>
  );
}
