import { Company, EditAddressForm } from '@/components/account/models';
import { callPostApi, callPostFormApi } from '@/components/api-common';

export type UpdateCompanyRequestBody = {
  company_name: string;
  profile?: EditAddressForm;
  logo_file_key?: string;
  logo_file_name?: boolean;
};

export const updateCompanyInfoOnSignupSuccess = async (
  params: UpdateCompanyRequestBody
) => {
  return callPostApi(
    'user/service/company/update_company_info_on_signup_success/',
    params
  );
};

export const getCompanyInfo = async () => {
  return callPostApi('/user/service/company/users/') as Promise<Company>;
};

export function uploadCompanyLogo(formData: FormData) {
  return callPostFormApi('/user/service/company/upload_file/', formData);
}
